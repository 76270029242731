import React, { useMemo, useState } from 'react';
import { historyStatusTypes } from 'lib/dictionaries';
import { HistoryItem } from 'stories';
import {
  IChangeOrderEvent,
  IChangeOrderEventLog,
} from 'bundles/Construction/types';
import { capitalize } from 'lodash-es';
import LoadingPanel from 'bundles/Shared/components/LoadingPanel';
import HistoryDetailsModal from '../ChangeOrderEvent/HistoryDetailsModalSwither/HistoryDetailsModal/HistoryDetailsModal';
import { getHistoryEventCO } from './../../actions/changeOrders';
import { connect, ConnectedProps } from 'react-redux';
import HistoryDetailsBodyCO from './HistoryDetailsModalHeaderContent/HistoryDetailsBodyCO';
import { getHistoryDataCO } from './HistoryDetailsModalHeaderContent/initData';

interface Props extends PropsFromRedux {
  currentObjectState: IChangeOrderEvent;
  logItem: IChangeOrderEventLog;
  firstItem?: boolean;
  legalEntityCode: string;
}

function HistoryElem({
  id,
  legalEntityCode,
  currentObjectState,
  logItem,
  firstItem,
  getHistoryEventCO,
}: Props) {
  const { data, createdBy, createdAt, title, documents, eventComment, kind } =
    logItem;
  if (!logItem) return <LoadingPanel />;
  const statusModals = ['update'];
  const [historyData, setHistoryData] = useState(false);

  const config = historyStatusTypes(data)[kind || 'default'];
  const configCO = useMemo(() => getHistoryDataCO(kind), []);

  const showDocument = (doc: File) => {
    window.open(`/shared-files/${doc.id}?disposition=inline`, '_blank');
  };

  const openHistoryModal = async (historyEvent) => {
    const data = await getHistoryEventCO(legalEntityCode, id, historyEvent.id);
    setHistoryData(data);
  };

  const generateLabel = () => {
    const label: string =
      kind === 'rollback' ? config.label : capitalize(title ?? config.label);
    return `${label ?? 'Created'} 
    ${currentObjectState?.number ? `CO ${currentObjectState?.number}` : ''} 
    ${config?.content ?? ''}`;
  };
  return (
    <div>
      <HistoryItem
        label={generateLabel()}
        createdAt={createdAt}
        createdBy={createdBy}
        icon={{
          iconName: config?.icon,
          iconColor: config?.color,
        }}
        emailDetails={eventComment}
        documents={documents}
        disabledDocument={config?.label === 'removed documents from'}
        notificationAction={
          statusModals.includes(kind)
            ? {
                action: openHistoryModal,
                sendData: logItem,
                label: 'Show Details',
              }
            : null
        }
        onClickDoc={showDocument}
        firstItem={firstItem}
      />
      {historyData && (
        <HistoryDetailsModal
          title={configCO?.title}
          eventDetails={historyData}
          onClose={setHistoryData}
          bodyContent={<HistoryDetailsBodyCO fields={historyData.fields} />}
        />
      )}
    </div>
  );
}

const mapActionCreators = {
  getHistoryEventCO,
};

const connector = connect(null, mapActionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(HistoryElem);
